<template>
    <div v-if='model && !model.is_null'>
        <div class='text-right last_update'>Data ultima modifica: {{ lastUpdateDate }}</div>
        <form @submit='save' @change='formIsDirty'>
            <div class='grid p-fluid formgrid'>
                <div class='field col-12 md:col-6'>
                    <div class="p-inputgroup">
                    <span class='p-float-label'>
                        <InputText id='author'
                                   type='text'
                                   v-model="v$.model.author.$model"
                                   :class="{'p-invalid': v$.model.author.$errors.length}"
                        />
                        <label for='author'>Autore</label>
                    </span>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-8'>
                    <div class='p-inputgroup'>
                        <Button :icon="categoriesRefreshLoadingIcon" class="p-button-warning" @click='refreshCategories'/>
                        <span class='p-float-label'>
                        <MultiSelect
                            id='categories'
                            v-model="v$.model.categories.$model"
                            :options="dd_categories"
                            :class="{'p-invalid': v$.model.categories.$errors.length}"
                            optionLabel="label"
                            dataKey="id"
                            :showToggleAll='false'
                            display="chip"/>
                        <label for='categories'>Categorie</label>
                    </span>
                    </div>

                </div>
            </div>

            <div class='grid p-justify-center mt-4'>
                <div class='col-12 text-center'>
                    <SaveButton @click='save' :load='loading'/>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('Cont/Quotes');
import useVuelidate from '@vuelidate/core'

import model from './model';

import Navigations from '../../../mixins/navigations';
import Notifications from '../../../mixins/notifications';
import DropdownCategories from '../../../mixins/dd_categories';

export default {
    mounted(){
        this.model = this.row;
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations () {
        return model.modelValidation;
    },
    computed: {
        ...mapGetters(['row', 'loading']),
    },
    watch: {
        row(n){
            this.model = n;
        }
    },
    methods: {
        ...mapActions(['savePlain','createPlain']),
        ...mapMutations(['formIsDirty', 'formIsClean']),
        async save () {
            const result = await this.v$.$validate()
            if (!result) {
                this.ShowError("Errore", "Alcuni campi del form non sono corretti");
                return
            }
            const save = (this.model.id > 0) ? this.savePlain : this.createPlain;
            const changeRoute = (this.model.id === 0);

            save(this.model).then( resp => {
                this.formIsClean();
                if (changeRoute){
                    this.navigateToId(resp.id).then( () => {
                        this.ShowSuccess('Ottimo', 'Citazione creata con successo');
                    });
                    return;
                }
                this.ShowSuccess('Ottimo', 'Citazione salvata con successo');

            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
    },
    mixins: [
        Navigations,
        Notifications,
        DropdownCategories
    ]
};
</script>

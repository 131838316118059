import tools from '../../../libs/tools';
import { minLength, required } from '@vuelidate/validators';

const model = {
    id: "",
    categories: [],
    author: "",
    translations: {}
};
const translation = {
    id: 0,
    publish_state: "",
    pub_at: null,
    quote_id: "",
    locale: "",
    text: "",
    updated_by: "",
    updated_at: new Date().toISOString()
};



export default {
    ...tools.models.apiCommons(model, translation, "quote_id"),
    modelValidation: {
        model: {
            author: {
                required
            },
            categories: {
                required,
                minLength: minLength(1)
            }
        }
    },
    translationValidation: {
        model: {
            text: { required },
            publish_state: { required },
            pub_at: {
                validDate: tools.models.validators.PUBLISH_DATE
            }
        }
    }
};
